<template>
  <a-spin :spinning="pageLoadFlag" size="large">
    <breadcrumb :data="breadcrumbData"></breadcrumb>
    <div class="bigBox">
      <p>{{yearStr}}年{{ monthStr }}月代理商市场活动总计完成核销{{ money }}元，完成核销活动信息如下：</p>
      <div class="tableBox">
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
        >
        </a-table>
      </div>
    </div>
  </a-spin>
</template>
<script>
import { agentActivityApplyNotice } from "@/views/center/noticeList/api";

export default {
  data() {
    return {
      content: "",
      monthStr: "",
      money: "",
      pageLoadFlag: false,
      tableLoading: false,
      yearStr:'',
      columns: [
        {
          title: "活动主题",
          dataIndex: "activityTheme"

        },
        {
          title: "活动编码",
          dataIndex: "activityCode"

        },
        {
          title: "发起时间",
          dataIndex: "applyMonth"

        },
        {
          title: "核销金额",
          dataIndex: "applyCost"
        }
      ],
      tableData: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/center/notice/detail",
          name: "noticeListDetail",
          title: "促销资源兑现"
        }
      ]
    };
  },
  mounted() {
    this.pageLoadFlag = true;
    this.content = this.$route.query.content;
    let id = this.$route.query.sourceId;
    if (id) {
      this.getDetail(id);
    } else {
      this.$message.warning("未获取到数据");
    }

  },
  methods: {
    //   获取详情
    getDetail(id) {
      agentActivityApplyNotice({ sourceId: id }).then(res => {
        this.pageLoadFlag = false;
        if (res.data.code == 0) {
          this.tableData = res.data.list;
          this.monthStr = res.data.list && res.data.list.length > 0 ? res.data.list[0].monthStr : "";
          this.money = res.data.list && res.data.list.length > 0 ? res.data.list[0].sumCost : "";
          this.yearStr = res.data.list && res.data.list.length > 0 ? res.data.list[0].yearStr : "";
        } else {
          this.$message.warning(res.data.msg);
        }

      });
    }
  }
};
</script>
<style lang="less" scoped>
.bigBox {
  width: 1188px;
  min-height: 483px;
  background: #FFFFFF;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 40px;

  .tableBox {
    margin: 0 auto;
    width: 1108px;
    overflow: auto;
    padding-bottom: 200px;
  }

  p {
    margin: 0 0 24px 40px;
    padding-top: 24px;
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    line-height: 22px;
  }

}
</style>